import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CartService from "../../services/CartService";
import { FiPlus, FiMinus } from "react-icons/fi";
import { toast } from "react-toastify";
import "./styles/cart-items.css";

export const CartItems = () => {
  const { cartId } = useParams();
  const cartService = new CartService();
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCart = async () => {
      try {
        setLoading(true);
        const response = await cartService.viewCart(cartId);
        setCart(response);
      } catch (error) {
        console.error("Error fetching cart items:", error);
        toast.error(
          error.response?.data?.message || "Failed to load cart items."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCart();
  }, [cartId]);

  const handleDeleteProduct = async (productUid) => {
    try {
      console.log(
        `Deleting product with UID: ${productUid} from cart: ${cartId}`
      );
      await cartService.removeItemFromCart(cartId, productUid);
      setCart({
        ...cart,
        items: cart.items.filter((item) => item.product.uid !== productUid),
      });
      toast.success("Product removed from cart successfully!");
    } catch (error) {
      console.error("Error removing product from cart:", error);
      toast.error(error.message || "Failed to remove product from cart.");
    }
  };

  const handleCheckoutCart = async () => {
    try {
      console.log(`Checking out cart with ID: ${cartId}`);
      const response = await cartService.checkoutCart(cartId);

      if (response?.url) {
        // Open the payment URL in a new tab
        window.open(response.url, "_blank");
        toast.success("Redirecting to payment page...");
      } else {
        toast.error("Payment URL not found. Please try again later.");
      }

      console.log("Checkout response:", response);
    } catch (error) {
      console.error("Error during cart checkout:", error);
      toast.error(error.message || "Failed to checkout cart.");
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!cart || !cart.items || cart.items.length === 0) {
    return <div className="no-items">No items found in this cart.</div>;
  }

  return (
    <div className="cart-items-section">
      <h2>Cart Items</h2>
      <div className="cart-details">
        <p>
          <strong>Created Date:</strong>{" "}
          {new Date(cart.date_created).toLocaleDateString()}
        </p>
        <p>
          <strong>Last Modified:</strong>{" "}
          {new Date(cart.last_modified).toLocaleDateString()}
        </p>
      </div>
      <div className="cart-items">
        {cart.items.map((item) => (
          <div className="cart-item" key={item.uid}>
            <div className="item-image">
              <img
                src={item.product.images[0]?.image}
                alt={item.product.name}
              />
            </div>
            <div className="item-details">
              <h3>{item.product.name}</h3>
              <p>{item.product.description}</p>
              <p>
                <strong>Price:</strong> R{item.product.price}
              </p>
              <p>
                <strong>Quantity:</strong> {item.qty}
              </p>
              <p>
                <strong>Total:</strong> R
                {(item.qty * parseFloat(item.product.price)).toFixed(2)}
              </p>
              <div className="cart-item-buttons">
                <button className="button">
                  <FiMinus />
                </button>
                <button className="button">
                  <FiPlus />
                </button>
                <button
                  className="button delete-btn"
                  onClick={() => handleDeleteProduct(item.product.uid)}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="checkout-btn" onClick={handleCheckoutCart}>
        Checkout Cart
      </button>
    </div>
  );
};
