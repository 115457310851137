import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CartService from "../../services/CartService";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import "./styles/carts.css";

export const Carts = () => {
  const cartService = new CartService();
  const [carts, setCarts] = useState([]);

  useEffect(() => {
    const fetchCarts = async () => {
      try {
        console.log("Fetching carts...");
        const response = await cartService.listCarts();
        console.log("Carts fetched:", response);

        // Sort and process the cart data
        const sortedCarts = response.results.sort(
          (a, b) => new Date(b.last_modified) - new Date(a.last_modified)
        );

        const processedCarts = sortedCarts.map((cart, index) => {
          // Determine the title based on the associated entity
          const shopName = cart.items?.[0]?.product?.shop?.name || null;
          const creatorName = cart.items?.[0]?.product?.creator
            ? `${cart.items[0].product.creator.first_name} ${cart.items[0].product.creator.last_name}`
            : null;
          const thrifterName = cart.items?.[0]?.product?.thrifter
            ? `${cart.items[0].product.thrifter.first_name} ${cart.items[0].product.thrifter.last_name}`
            : null;

          let title = "Cart Empty";
          if (shopName) {
            title = `Cart from Shop: ${shopName}`;
          } else if (creatorName) {
            title = `Cart from Creator: ${creatorName}`;
          } else if (thrifterName) {
            title = `Cart from Thrifter: ${thrifterName}`;
          } else {
            title = `Cart Empty ${index + 1}`;
          }

          return {
            id: cart.uid,
            title,
            totalQuantity: cart.items.reduce((sum, item) => sum + item.qty, 0),
            totalPrice: cart.items.reduce(
              (sum, item) => sum + item.qty * parseFloat(item.product.price),
              0
            ),
            createdDate: cart.date_created,
            lastModifiedDate: cart.last_modified,
          };
        });

        setCarts(processedCarts);
      } catch (error) {
        console.error("Error fetching carts:", error);
        toast.error("Failed to fetch carts. Please try again later.");
      }
    };

    fetchCarts();
  }, []);

  const deleteCart = async (cartId) => {
    try {
      console.log(`Deleting cart with ID: ${cartId}`);
      await cartService.deleteCart(cartId);
      setCarts(carts.filter((cart) => cart.id !== cartId));
      toast.success("Cart deleted successfully!");
    } catch (error) {
      console.error("Error deleting cart:", error);
      toast.error(error.message || "Failed to delete cart. Please try again.");
    }
  };

  return (
    <div className="cart-section">
      <h2>All Carts</h2>
      {carts.length > 0 ? (
        carts.map((cart) => (
          <div className="cart-item" key={cart.id}>
            <div className="cart-item-details">
              <p><strong>{cart.title}</strong></p>
              <p><strong>Total Items:</strong> {cart.totalQuantity}</p>
              <p><strong>Total Price:</strong> R{cart.totalPrice.toFixed(2)}</p>
              <p>
                <strong>Last Modified:</strong>{" "}
                {new Date(cart.lastModifiedDate).toLocaleDateString()}
              </p>
            </div>
            <div className="cart-item-buttons">
              <Link to={`/cart-items/${cart.id}`} className="checkout-btn">
                View Cart
              </Link>
              <button
                className="delete-btn"
                onClick={() => deleteCart(cart.id)}
              >
                <RiDeleteBinLine />
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No carts available.</p>
      )}
    </div>
  );
};
