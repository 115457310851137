import React, { useState, useEffect } from "react";
import { AdminDashboard } from "./AdminDashboard";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Loader } from "../../Common/Loader";
import BlogService from "../../../services/BlogService";
import { AdminAddBlog } from "./AdminAddBlog";
import "./styles/admin-blog.css";

const blogService = new BlogService();

export const AdminBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [editableFields, setEditableFields] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchBlogData();
    window.scrollTo(0, 0);
  }, []);

  const fetchBlogData = async () => {
    setIsLoading(true);
    try {
      const blogsData = await blogService.getBlogs("/blog/");
      setEditableFields(blogsData.results.map((blog) => ({ ...blog })));
      setBlogs(blogsData.results);
      setError(null);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setError("Failed to retrieve blogs. Please try again later.");
    }
    setIsLoading(false);
  };

  const handleInputChange = (e, index, field) => {
    const value = e.target.value;

    setEditableFields((prevFields) => {
      const updatedFields = [...prevFields];

      if (field === "title" && value.length > 60) {
        toast.error("Blog title cannot exceed 60 characters");
        return updatedFields;
      }

      if (field === "content" && value.length > 345) {
        toast.error("Blog content cannot exceed 345 characters");
        return updatedFields;
      }

      updatedFields[index][field] = value;
      return updatedFields;
    });
  };

  const handleImageChange = (event, index) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e, blog_id, index) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let objectUrl = "";

      if (selectedImage) {
        const formData = new FormData();
        formData.append("image", selectedImage);
        objectUrl = await blogService.uploadImage(formData);
      }

      const updatedBlogData = {
        ...editableFields[index],
        blog_img: objectUrl || editableFields[index].blog_img,
      };

      await blogService.updateBlog(blog_id, updatedBlogData);
      toast.success("Blog updated successfully!");
    } catch (error) {
      console.error("Error updating blog details:", error);
      toast.error("Error updating blog details");
    }

    setIsLoading(false);
  };

  // DELETE BLOG HANDLER
  const handleDelete = async (blog_id) => {
    setIsLoading(true);
    try {
      await blogService.deleteBlog(blog_id);
      toast.success("Blog deleted successfully!");
      fetchBlogData(); // Refresh the blog list
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error("Error deleting blog");
    }
    setIsLoading(false);
  };

  return (
    <>
      <AdminDashboard />
      <div className="admin-blog">
        <Helmet>
          <title>Creatives SA - Edit Blog</title>
        </Helmet>

        <h1>Edit Blogs</h1>
        <AdminAddBlog />
        {error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div>
            {blogs.map((blog, index) => (
              <form
                key={blog.uid}
                onSubmit={(e) => handleSubmit(e, blog.uid, index)}
                className="blog-form"
              >
                <div className="form-group">
                  <label>
                    Blog Heading:
                    <input
                      type="text"
                      name="blog_heading"
                      value={editableFields[index].title}
                      onChange={(e) => handleInputChange(e, index, "title")}
                      maxLength="60"
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    Blog Description:
                    <textarea
                      className="text-area"
                      type="text"
                      name="blog_description"
                      value={editableFields[index].content}
                      onChange={(e) => handleInputChange(e, index, "content")}
                      maxLength="345"
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    Blog Date:
                    <input
                      type="text"
                      name="blog_date"
                      value={editableFields[index].date_posted}
                      onChange={(e) =>
                        handleInputChange(e, index, "date_posted")
                      }
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    Blog Image:
                    <div className="image-upload">
                      <img
                        src={
                          previewImage ||
                          editableFields[index].blog_img ||
                          "https://via.placeholder.com/150"
                        }
                        alt="Blog Preview"
                        className="blog-img"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        name="blog_img"
                        onChange={(e) => handleImageChange(e, index)}
                      />
                    </div>
                  </label>
                </div>
                <button type="submit">Save Changes</button>
                <button type="button" onClick={() => handleDelete(blog.uid)}>
                  Delete
                </button>
              </form>
            ))}
          </div>
        )}
        {isLoading && <Loader message="Processing..." />}
      </div>
    </>
  );
};
