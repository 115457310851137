import React, { createContext, useContext, useState, useEffect } from "react";
import CartService from "../../services/CartService";
import { toast } from "react-toastify";

export  const CartContext = createContext();

const cartService = new CartService();

export const CartProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const addToCart = async (product) => {
    const shopUid = product.shop.uid;
    const productUid = product.uid;
    const qty = 1;

    try {
      console.log("Starting addToCart process...");
      console.log(`Product to add: ${product.name} (UID: ${productUid})`);
      console.log(`Shop UID: ${shopUid}`);

      setLoading(true);

      // Step 1: Check if a cart exists for the shop
      console.log("Fetching all carts...");
      const cartsResponse = await cartService.listCarts();
      console.log("Carts fetched:", cartsResponse);

      const existingCart = cartsResponse.results.find((cart) => cart.shop === shopUid);

      let cartId;

      if (existingCart) {
        // Step 2: If a cart exists, use it
        console.log(`Cart found for shop UID ${shopUid}:`, existingCart);
        cartId = existingCart.uid;
      } else {
        // Step 3: If no cart exists, create a new one
        console.log(`No cart found for shop UID ${shopUid}. Creating a new cart...`);
        const newCart = await cartService.createCart(shopUid);
        console.log("New cart created:", newCart);
        cartId = newCart.uid;
      }

      // Step 4: Add the product to the cart
      console.log(`Adding product to cart UID: ${cartId}`);
      const addItemResponse = await cartService.addItemToCart(cartId, productUid, qty);
      console.log("Product added to cart successfully:", addItemResponse);

      toast.success(`${product.name} added to the cart!`);
    } catch (error) {
      console.error("Error in addToCart:", error);
      toast.error("Failed to add item to cart.");
    } finally {
      setLoading(false);
      console.log("addToCart process complete.");
    }
  };

  return (
    <CartContext.Provider value={{ addToCart, loading }}>
      {children}
    </CartContext.Provider>
  );
};


export const useCart = () => useContext(CartContext);
